<template>
  <v-snackbar
    :value="notifications.text ? true : false"
    top
    right
    :color="notifications.type"
  >
    {{ notifications.text }}
    <template #action="{ attrs }">
      <v-btn
        color="white"
        text
        v-bind="attrs"
        @click="$store.dispatch('notifications/removeNotification')"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "NotificationBar",
  computed: {
    notifications() {
      return this.$store.state.notifications.message;
    },
  },
};
</script>
