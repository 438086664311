<template>
  <v-app>
    <v-app-bar app color="accent" prominent height="110">
      <div class="d-flex align-center header-wrap" style="width: 100%">
        <v-img
          alt="Senior Life Insurance Company"
          class="shrink mt-1 header-logo"
          contain
          :max-width="$vuetify.breakpoint.mdAndDown ? 120 : 160"
          src="@/assets/images/Full-Logo-Gold.png"
          :width="$vuetify.breakpoint.mdAndDown ? 120 : 160"
        />

        <div class="text-center">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item two-line class="menu-text">
                <v-list-item-content>
                  <v-list-item-title class="text-right"
                    >{{ user.firstName }} {{ user.lastName }}</v-list-item-title
                  >
                </v-list-item-content>
                <v-btn v-bind="attrs" v-on="on" icon color="#fff">
                  <i class="fas fa-sort-down"></i>
                </v-btn>
              </v-list-item>
            </template>
            <v-list>
              <v-list-item to="/admin" v-if="isAdmin">
                <v-list-item-title>Admin Panel</v-list-item-title>
              </v-list-item>
              <v-list-item to="/receipts" v-if="!isAdmin">
                <v-list-item-title>Receipts List</v-list-item-title>
              </v-list-item>
              <v-list-item to="/receipt-scanner">
                <v-list-item-title>Receipt Scanner</v-list-item-title>
              </v-list-item>
              <v-list-item to="/register" v-if="isAdmin">
                <v-list-item-title>Registration</v-list-item-title>
              </v-list-item>
              <v-list-item to="/categories" v-if="isAdmin">
                <v-list-item-title>Categories</v-list-item-title>
              </v-list-item>
              <v-list-item to="/events" v-if="isAdmin">
                <v-list-item-title>Events</v-list-item-title>
              </v-list-item>
              <v-list-item to="/cards" v-if="isAdmin">
                <v-list-item-title>Cards</v-list-item-title>
              </v-list-item>
              <v-list-item to="/change-password">
                <v-list-item-title>Change Password</v-list-item-title>
              </v-list-item>
              <v-list-item @click="logout()">
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>

      <v-spacer></v-spacer>
    </v-app-bar>
    <router-view></router-view>
    <Footer />
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import Footer from "@/components/Footer.vue";
import AuthService from "@/services/AuthService";

export default {
  components: {
    Footer,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    isAdmin() {
      return ["Admin", "Super Admin"].includes(this.user.roleName);
    },
  },
  methods: {
    async logout() {
      await AuthService.logout();
      await this.$store.dispatch("RESET_STORE");
      this.$router.push("/login");
    },
  },
};
</script>
