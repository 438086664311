import { redirectPageName } from "../utils/helpers";
const allowedAdminRoles = ["Admin", "Super Admin"];

export default function admin({ next, store }) {
  if (!allowedAdminRoles.includes(store.getters["auth/auth"].roleName)) {
    return next({
      name: redirectPageName(),
    });
  }

  return next();
}
