<template>
  <v-card>
    <v-footer style="padding: 0">
      <v-card flat tile width="100%" class="accent text-center">
        <v-card-text>
          <div class="d-flex justify-space-between flex-column flex-sm-row">
            <div class="copyright">
              Senior Life Insurance Company Ⓒ Copyright
              {{ new Date().getFullYear() }}

              <!-- —
              <strong>All Rights Reserved. </strong> -->
            </div>
            <div class="contact-info">
              <a
                href="mailto:receipts@srlife.net"
                accent
                class="mr-5 mb-3 mb-md-0 text-md-body-1 text-decoration-none mt-3 mt-sm-0 text-center align-center"
                ><i class="fa fa-envelope mt-1 mr-2"></i> receipts@srlife.net</a
              >
              <a
                href="tel:+18777778808"
                accent
                class="text-md-body-1 text-decoration-none mb-0 mb-sm-3 mb-md-0 text-center"
                ><i class="fa fa-phone mt-1 mr-2"></i> 877.777.8808</a
              >
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-card>
</template>

<script>
export default {
  name: "Footer",
};
</script>
