import http from "./http-client";

class UserService {
  register(data) {
    return http.post("/User/registration", data);
  }
  changePassword(data) {
    return http.post("/User/change-password", data);
  }
  getRegisteredList() {
    return http.get("/User/getUsers");
  }
  getSingleUser(id) {
    return http.get("/User/getUsersById?id=" + id);
  }
  forgotPassword(data) {
    return http.post("/User/forgot-password", data);
  }
  verifyToken(token) {
    return http.get("/User/verify-link?token=" + token);
  }
  resetPassword(data) {
    return http.post("/User/reset-password", data);
  }
  editUser(data) {
    return http.put("/User/update-user", data);
  }
}

export default new UserService();
