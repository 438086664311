import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import receipt from "./modules/receipt";
import notifications from "./modules/notifications";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isUserEddited: false,
  },
  mutations: {
    setUserEddited(state, flag) {
      state.isUserEddited = flag;
    },
  },
  actions: {
    SET_USER_EDDITED({ commit }, flag) {
      commit("setUserEddited", flag);
    },
    RESET_STORE({ commit }) {
      commit("setUserEddited", false);
      commit("auth/resetAuth");
      commit("receipt/resetReceipt");
    },
  },
  modules: {
    auth,
    notifications,
    receipt,
  },
});
