import http from "./http-client";

class HomeService {
  getCategories() {
    return http.get("/Home/getCategories");
  }
  getEvents() {
    return http.get(`/Home/getEvents`);
  }
  getRoles() {
    return http.get("/Home/getRoles ");
  }
  getCards() {
    return http.get("/Home/getCardTypes?userId=0");
  }
  getUserCards(id) {
    return http.get("/Home/getCardTypes?userId=" + id);
  }
  addEditCategory(data) {
    return http.post("/Home/add-update-category", data);
  }
  addEditEvent(data) {
    return http.post("/Home/add-update-event", data);
  }
  addEditCard(data) {
    return http.post("/Home/add-update-cardType", data);
  }
}

export default new HomeService();
