import http from "./http-client";

class AuthService {
  login(payload) {
    return http.post("/Login", payload);
  }

  register(data) {
    return http.post("/User/registration", data);
  }

  changePassword(payload) {
    return http.post("/User/change-password", payload);
  }

  forgotPassword(data) {
    return http.post("/User/forgot-password", data);
  }

  logout() {
    return http.post("/User/logout");
  }
}

export default new AuthService();
