import store from "../../store";
const redirectPageName = () => {
  const isLoggedIn = store.getters["auth/loggedIn"];
  const role = store.state.auth.roleName;
  if (isLoggedIn && ["Admin", "Super Admin"].includes(role)) {
    return "AdminPanel";
  }
  if (isLoggedIn && role === "Staff") {
    return "ReceiptScanner";
  }
  if (!isLoggedIn) {
    return "Login";
  }

  return "Login";
};

/**
 *
 * @param {HTMLCanvas} canvas
 * @returns Boolean
 * Returns true if every pixel's uint32 representation is 0 (or "blank")
 */

const isCanvasBlank = (canvas) => {
  const context = canvas.getContext("2d");

  const pixelBuffer = new Uint32Array(
    context.getImageData(0, 0, canvas.width, canvas.height).data.buffer
  );

  return !pixelBuffer.some((color) => color !== 0);
};

const dataURItoBlob = (dataURI) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};

export { redirectPageName, isCanvasBlank, dataURItoBlob };
