import Vue from "vue";
import VueRouter from "vue-router";

// Import the layout files
import Dash from "@/components/Dash.vue";

// Import the middleware functions
import { auth, admin, guest, middlewarePipeline } from "../middleware";

// Import the store
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: "Login" },
  },
  {
    path: "/login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    meta: { title: "Login", middleware: [guest] },
  },
  {
    path: "/",
    component: Dash,
    children: [
      {
        path: "/receipt/edit/:id",
        name: "EditReceiptScanner",
        component: () =>
          import(
            /* webpackChunkName: "receipt-scanner" */ "../views/ReceiptScanner.vue"
          ),
        meta: { title: "Edit Receipt", middleware: [auth, admin] },
      },
      {
        path: "/receipt-scanner",
        name: "ReceiptScanner",
        component: () =>
          import(
            /* webpackChunkName: "receipt-scanner" */ "../views/ReceiptScanner.vue"
          ),
        meta: { title: "Receipt Scanner", middleware: [auth] },
      },
      {
        path: "/admin",
        name: "AdminPanel",
        component: () =>
          import(/* webpackChunkName: "admin" */ "../views/AdminPanel.vue"),
        meta: { title: "Admin Panel", middleware: [auth, admin] },
      },
      {
        path: "/receipts",
        name: "ReceiptsList",
        component: () =>
          import(
            /* webpackChunkName: "receipts-list" */ "../views/Receipts.vue"
          ),
        meta: { title: "Receipts", middleware: [auth] },
      },
      {
        path: "/register",
        name: "Register",
        component: () =>
          import(/* webpackChunkName: "register" */ "../views/Register.vue"),
        meta: { title: "Register", middleware: [auth, admin] },
      },
      {
        path: "/change-password",
        name: "ChangePassword",
        component: () =>
          import(
            /* webpackChunkName: "change-password" */ "../views/ChangePassword.vue"
          ),
        meta: { title: "Change Password", middleware: [auth] },
      },
      {
        path: "/user/edit/:id",
        name: "EditUser",
        component: () =>
          import(/* webpackChunkName: "edit-user" */ "../views/EditUser.vue"),
        meta: { title: "Edit User", middleware: [auth, admin] },
      },
      {
        path: "/categories",
        name: "Categories",
        component: () =>
          import(
            /* webpackChunkName: "categories" */ "../views/Categories.vue"
          ),
        meta: { title: "Categories", middleware: [auth, admin] },
      },
      {
        path: "/events",
        name: "Events",
        component: () =>
          import(/* webpackChunkName: "events" */ "../views/Events.vue"),
        meta: { title: "Events", middleware: [auth, admin] },
      },
      {
        path: "/cards",
        name: "Cards",
        component: () =>
          import(/* webpackChunkName: "cards" */ "../views/CardType.vue"),
        meta: { title: "Cards", middleware: [auth, admin] },
      },
    ],
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ "../views/ForgotPassword.vue"
      ),
    meta: { title: "Forgot Password", middleware: [guest] },
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ "../views/ResetPassword.vue"
      ),
    meta: { title: "Reset Password", middleware: [guest] },
  },
  {
    path: "/404",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "not-found" */ "../views/NotFoundPage.vue"),
  },
  {
    path: "*",
    redirect: { name: "NotFound" },
  },
];

const router = new VueRouter({
  routes,
  // mode: 'history',
  // linkExactActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    }
    if (savedPosition) {
      return savedPosition;
    } else {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ left: 0, top: 0 });
        }, 10);
      });
    }
  },
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "Receipt Scanner";

  if (!to.meta.middleware) {
    return next();
  }
  const middleware = to.meta.middleware;

  const context = {
    to,
    from,
    next,
    store,
  };

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
