import HomeService from "../../services/HomeService";
import UserService from "@/services/UserService";

export default {
  namespaced: true,
  state: {
    categories: [],
    events: [],
    users: [],
    cards: [],
    loading: false,
  },
  actions: {
    FETCH_COMMON_DATA: ({ commit, dispatch }) => {
      commit("setLoading", true);
      HomeService.getCategories()
        .then((res) => {
          commit("setCategories", res.data.data);
        })
        .catch((error) => {
          commit("setLoading", false);
          dispatch(
            "notifications/setNotification",
            {
              text: "Error While Fetching Categories",
              type: "error",
              status: error.response.status,
            },
            { root: true }
          );
        });
      HomeService.getEvents()
        .then((res) => {
          commit("setEvents", res.data.data);
          commit("setLoading", false);
        })
        .catch((error) => {
          commit("setLoading", false);
          dispatch(
            "notifications/setNotification",
            {
              text: "Error While Fetching Events",
              type: "error",
              status: error.response.status,
            },
            { root: true }
          );
        });
      UserService.getRegisteredList()
        .then((res) => {
          commit("setUsers", res.data.data);
          commit("setLoading", false);
        })
        .catch((error) => {
          commit("setLoading", false);
          dispatch(
            "notifications/setNotification",
            {
              text: "Error While Fetching Users",
              type: "error",
              status: error.response.status,
            },
            { root: true }
          );
        });
    },
    FETCH_CARDS: ({ commit, dispatch }) => {
      commit("setLoading", true);
      HomeService.getCards()
        .then((res) => {
          commit("setLoading", false);
          commit("setCards", res.data.data);
        })
        .catch((error) => {
          commit("setLoading", false);
          dispatch("notifications/setNotification", {
            type: "error",
            text: error.response.data.errorMessage,
            status: error.response.status,
          });
        });
    },
  },
  mutations: {
    setCategories: (state, data) => {
      state.categories = data;
    },
    setEvents: (state, data) => {
      state.events = data;
    },
    setUsers: (state, data) => {
      state.users = data;
    },
    setLoading: (state, flag) => {
      state.loading = flag;
    },
    addCategory: (state, category) => {
      state.categories.unshift(category);
    },
    editCategory: (state, category) => {
      const index = state.categories.findIndex((cat) => cat.id === category.id);
      state.categories.splice(index, 1, category);
    },
    addEvent: (state, event) => {
      state.events.unshift(event);
    },
    editEvent: (state, event) => {
      const index = state.events.findIndex((eve) => eve.id === event.id);
      state.events.splice(index, 1, event);
    },
    setCards: (state, data) => {
      state.cards = data;
    },
    addCard: (state, card) => {
      state.cards.unshift(card);
    },
    editCard: (state, card) => {
      const index = state.cards.findIndex((car) => car.id === card.id);
      state.cards.splice(index, 1, card);
    },
    resetReceipt: (state) => {
      state.categories = [];
      state.events = [];
      state.users = [];
      state.cards = [];
      state.loading = false;
    },
  },
};
